module.exports = {
  siteMetadata: {
    title: 'SwellEnergy.com',
  },
  plugins: [
    {
      resolve: 'gatsby-plugin-google-analytics',
      options: {
        trackingId: process.env.TARGET_ENV === 'production' ? 'UA-57579537-1' : 'UA-57579537-6',
        head: true,
        enableWebVitalsTracking: true,
        defer: true,
      },
    },
    'gatsby-plugin-react-helmet',
    {
      resolve: `gatsby-plugin-react-helmet-canonical-urls`,
      options: {
        siteUrl:
          process.env.TARGET_ENV === 'production'
            ? 'https://www.swellenergy.com'
            : 'https://sbx-www.swellenergy.com',
        exclude: ['/partners'],
      },
    },
    {
      resolve: '@sentry/gatsby',
      options: {
        dsn: 'https://95dedf07b0094df7883b803bc63de788@o458648.ingest.sentry.io/5456505',
        environment: process.env.TARGET_ENV,
        tracesSampleRate: 0.2,
        ignoreErrors: [
          "Can't find variable: UET",
          'UET is not defined',
          'Not rendering React',
          'is not a function',
          'We couldn\'t load "/page-data/sq/d',
          'Failed to get the interest cohort: either it is unavailable, or preferences or content settings have denied access',
        ],
      },
    },
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: `${__dirname}/src/images`,
      },
    },
    'gatsby-plugin-sass',
    'gatsby-plugin-svgr',
    {
      resolve: 'gatsby-source-storyblok',
      options: {
        accessToken: 'IHepelgBG4QKNSelunaL6Att',
        homeSlug: 'home',
        version: process.env.TARGET_ENV === 'production' ? 'published' : 'draft',
        resolveLinks: 'url',
      },
    },
    {
      resolve: 'gatsby-plugin-styled-components',
      options: {
        displayName: false,
        fileName: false,
      },
    },
    'gatsby-transformer-sharp',
    'gatsby-plugin-sharp',
    'gatsby-plugin-meta-redirect',
    {
      resolve: 'gatsby-plugin-manifest',
      options: {
        name: 'Swell Energy',
        short_name: 'Swell',
        start_url: '/',
        background_color: '#f5f5f5',
        theme_color: '#2b96f4',
        display: 'standalone',
        icon: 'src/images/icon.png',
      },
    },
  ],
};
