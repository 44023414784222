import React from 'react';
import Components from '../components/components.js';
import SbEditable from 'storyblok-react';
import config from '../../gatsby-config';
import { getComponentForBlok } from '../components/NxComponents';
import { EnvironmentContext } from '../components/EnvironmentContext';
import { getPageStylesValues } from '../utils/getPageStylesValues';

let sbConfigs = config.plugins.filter((item) => {
  return item.resolve === 'gatsby-source-storyblok';
});
let sbConfig = sbConfigs.length > 0 ? sbConfigs[0] : {};

const loadStoryblokBridge = function (cb) {
  let script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = `//app.storyblok.com/f/storyblok-latest.js?t=${sbConfig.options.accessToken}`;
  script.onload = cb;
  document.getElementsByTagName('head')[0].appendChild(script);
};

const getParam = function (val) {
  var result = '';
  var tmp = [];

  window.location.search
    .substr(1)
    .split('&')
    .forEach(function (item) {
      tmp = item.split('=');
      if (tmp[0] === val) {
        result = decodeURIComponent(tmp[1]);
      }
    });

  return result;
};

class StoryblokEntry extends React.Component {
  constructor(props) {
    super(props);

    const isProd =
      typeof window !== 'undefined' &&
      window.location.origin.indexOf('swellenergy.com') > -1 &&
      window.location.origin.indexOf('sbx') === -1;
    this.state = { story: null, isProd };
  }

  componentDidMount() {
    loadStoryblokBridge(() => {
      this.initStoryblokEvents();
    });
  }

  loadStory(payload) {
    window.storyblok.get(
      {
        slug: getParam('path'),
        version: getParam('_storyblok_published') ? 'published' : 'draft',
        resolve_relations: sbConfig.options.resolveRelations || [],
      },
      (data) => {
        this.setState({ story: data.story });
      }
    );
  }

  initStoryblokEvents() {
    this.loadStory({ storyId: getParam('path') });

    let sb = window.storyblok;

    sb.on(['change', 'published'], (payload) => {
      this.loadStory(payload);
    });

    sb.on('input', (payload) => {
      if (this.state.story && payload.story.id === this.state.story.id) {
        payload.story.content = sb.addComments(payload.story.content, payload.story.id);
        this.setState({ story: payload.story });
      }
    });

    sb.pingEditor(() => {
      if (sb.inEditor) {
        sb.enterEditmode();
      }
    });
  }

  render() {
    if (this.state.story == null) {
      return <div></div>;
    }

    let content = this.state.story.content;

    if (content.component && content.component === 'global_navi') {
      content.theme = 'light';
    }

    if (content.component === 'nx_page') {
      return (
        <SbEditable content={content}>
          <EnvironmentContext.Provider
            value={{
              isInEditor: true,
              environment: this.state.isProd ? 'prod' : 'dev',
              pageStyles: getPageStylesValues(this.state.story.content),
              storyName: this.state.story.full_slug,
            }}
          >
            <div>{getComponentForBlok(content)}</div>
          </EnvironmentContext.Provider>
        </SbEditable>
      );
    } else {
      return (
        <SbEditable content={content}>
          <div>
            {React.createElement(Components(content.component), {
              key: content._uid,
              blok: content,
            })}
          </div>
        </SbEditable>
      );
    }
  }
}

export default StoryblokEntry;
